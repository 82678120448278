import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
// import { StaticImage } from "gatsby-plugin-image"

import { Container, Row, Col } from "react-bootstrap"

import generator from "../../images/generator.jpg"
import kitchen from "../../images/kitchen-light.jpg"

const SecondPage = () => (
  <Layout>
    <Seo title="Residential Electrical Services" />
    <h1>Residential Electrical Services</h1>
    <p>Your home is your most valuable asset. When you want a residential electrician who does exceptional work, you can depend on Thomas Tripolt of All Electric. Thomas has been serving homeowners throughout Marin County with the reliability and integrity of European craftsmanship since 1984. Rather than working with a member of the All Electric team, you will always see Thomas at your doorstep. His attention to detail and depth of experience ensures that you will receive the highest quality results.</p>
    <p className="text-center">
      <img src={kitchen} alt="Home Kitchen" className="img-fluid" />
       
    </p>
    <p><strong>All Electric’s Residential Electrical Services include:</strong></p>
    <Container>
      <Row>
        <Col lg={4}>
          <ul>
            <li>Home rewiring</li>
            <li>Lighting design and consultation</li>
            <li>Scene lighting</li>
            <li>Lighting automation</li>
            <li>Landscape lighting</li>
            <li>Energy efficient LED lighting</li>
            <li>Security lighting</li>
            <li>Hot tub installation and wiring</li>
          </ul>
        </Col>
        <Col lg={4}>
          <ul>
            <li>Main service upgrades</li>
            <li>Power analysis</li>
            <li>Upgrade to AFCI breakers</li>
            <li>Recessed lighting</li>
            <li>Wall-mounted television unit installation</li>
            <li>Audio wiring</li>
            <li>Intercom installation</li>
            <li>Network wiring</li>
          </ul>
        </Col>
        <Col lg={4}>
          <ul>
            <li>Whole house surge protection</li>
            <li>Under cabinet lighting</li>
            <li>Ceiling and attic fan installation</li>
            <li>Chandelier installation</li>
            <li>Dimmers</li>
            <li>Emergency generator installation</li>
            <li>Timer and occupancy sensors</li>
            <li>Expert trouble shooting</li>
            <li>Much more…</li>
          </ul>
        </Col>
      </Row>
      <p className="text-center">
      <img src={generator} alt="portable generator" className="img-fluid" />       
    </p>
    </Container>
  </Layout>
)

export default SecondPage
